import _ from 'lodash'
import { createShallowEqualSelector } from 'svr/common/SelectorUtils'

import { svrExport } from '@sevenrooms/core/ui-kit/utils'

export { ManagerPermissions } from './ManagerPermissions'

const NavigationPermissions = {
  get: ({ userDomain, venueSettings }) => {
    // TODO: eventually would like to remove all reference to privilege levels
    // and rely only on feature flags. This will help support custom roles / groups.
    if (_.isEmpty(venueSettings)) {
      return {}
    }
    const isBasicUser = userDomain.venue_privilege_level === 'BASIC'
    const isSubManagerUser = userDomain.venue_privilege_level === 'SUBMANAGER'
    const isManagerUser = userDomain.venue_privilege_level === 'MANAGER'
    const isUserManagerUser = userDomain.venue_privilege_level === 'USERMANAGER'
    const isSuperUser = userDomain.venue_privilege_level === 'SUPERUSER'
    const isOnboardingSpecialist = userDomain.venue_privilege_level === 'FLOORPLANEDITOR'
    const venueManagement = isSubManagerUser || isBasicUser || isSuperUser || isManagerUser
    const isStandaloneMarketing = ['MARKETING_ALPHA', 'MARKETING_BETA'].includes(venueSettings.product_provision_package)

    // make sure this matches appropriately with
    // frontend/packages/mgr-core/hooks/useNavigationPermissionsContext.ts
    return {
      canViewReservations: venueManagement && userDomain.can_access_operations_tab,
      canAccessOperationsTab: userDomain.can_access_operations_tab,
      canViewGrid: venueSettings.grid_page_enabled && !isOnboardingSpecialist && !isUserManagerUser && userDomain.can_access_operations_tab,
      canViewGuestlist: venueSettings.guestlist_enabled && userDomain.has_guestlist_access && !isUserManagerUser,
      canViewRequests: venueManagement && userDomain.can_access_requests_tab,
      canViewClients: venueManagement && userDomain.can_access_client_tab,
      canViewThirdPartyClientDbSearch: venueManagement && userDomain.can_view_third_party_client_db_search,
      canViewReporting: venueManagement,
      canViewReportingFull: userDomain.can_view_reporting_full,
      legacyReportsAccess: userDomain.legacy_reports_access,
      canViewActuals: userDomain.can_view_actuals,
      canViewMarketing: venueSettings.marketing_tab_enabled && userDomain.can_access_marketing_tools,
      canManageEmailsWithGlobalTags: userDomain.can_manage_emails_with_global_tags,
      canViewOrders: userDomain.can_view_orders && venueSettings.is_orders_integration_enabled,
      canViewEvents: venueSettings.marketing_tab_enabled && venueSettings.event_widget_enabled && venueManagement,
      canViewSizzle: venueSettings.is_sizzle_enabled && venueManagement,
      canAdministrateGeneralSettings: userDomain.can_administrate_general_settings,
      canAdministratePaymentGateways: userDomain.can_administrate_payment_gateways,
      canAccessSettingsNav: userDomain.can_access_settings_nav,
      canAccessClientTab: userDomain.can_access_client_tab,
      canAccessInsightsPage: userDomain.can_access_insights_page,
      canAccessPrivateEvents: userDomain.can_access_private_events_tab,
      hideActualsReport: userDomain.hide_actuals_report,
      canAdministrateWidgetSettings: userDomain.can_administrate_widget_settings,
      canManageVenueSettings: userDomain.can_manage_venue_settings,
      canManageRestrictedSettings: userDomain.can_manage_restricted_settings,
      canManageBookingChannels: userDomain.can_manage_booking_channels,
      canManageTagList: userDomain.can_manage_tag_list && venueManagement,
      canManageClientTagList: userDomain.can_manage_client_tag_list && venueManagement,
      canManageReservationTagList: userDomain.can_manage_reservation_tag_list && venueManagement,
      canManageMembershipGroups: venueSettings.membership_enabled && userDomain.can_manage_membership_groups && venueManagement,
      canViewSearch: venueManagement,
      canViewInbox: venueSettings.is_consumer_feature_enabled && venueSettings.vip_chat_enabled && userDomain.can_access_vip_chat,
      isSuperUser,
      isManagerUser,
      isOnboardingSpecialist,
      canViewOutgoingEmails: userDomain.can_view_outgoing_emails,
      canEditFloorplanLayout: userDomain.can_edit_floorplan_layout && !isOnboardingSpecialist,
      isOrdersIntegrationEnabled: venueSettings.is_orders_integration_enabled,
      isSevenroomsOrderingEnabled: venueSettings.is_sevenrooms_ordering_enabled,
      isSmsMarketingEnabled: venueSettings.is_sms_marketing_enabled,
      isWaitlistSmsEnabled: venueSettings.waitlist_sms_enabled,
      isReservationSmsEnabled: venueSettings.reservations_sms_enabled,
      isOrderingSmsEnabled: venueSettings.ordering_sms_enabled,
      isRemindersSmsEnabled: venueSettings.reminders_sms_enabled,
      isRoInsightEnabled: venueSettings.is_ro_insight_enabled,
      superheroRoAccess: venueSettings.superhero_ro_access,
      showOrderMenuSms: venueSettings.show_order_menu_sms,
      redirectToGeneralSettings: userDomain.can_administrate_general_settings,
      redirectToTagsList: userDomain.can_manage_tag_list,
      redirectToShifts: userDomain.can_administrate_shifts_access_rules,
      redirectToDailyProgram: userDomain.can_administrate_blackout_dates_daily_program,
      redirectToReservationWidget: userDomain.can_administrate_widget_settings,
      redirectToFloorplan: userDomain.can_edit_floorplan_layout,
      redirectToUserList: userDomain.can_manage_user_config,
      redirectToUserAccountsCRM: userDomain.can_manage_user_accounts_crm,
      canAdministrateShiftsAccessRules: userDomain.can_administrate_shifts_access_rules,
      canAdministrateBlackoutDatesDailyProgram: userDomain.can_administrate_blackout_dates_daily_program,
      isEmailMarketingEnabled: venueSettings.batch_emails_enabled,
      isLandingPageEnabled: venueSettings.landing_page_enabled,
      priorityAlertsAdminEnabled: venueSettings.priority_alerts_admin_enabled,
      vipChatEnabled: venueSettings.vip_chat_enabled,
      isStandaloneMarketing,
      isLoyaltyProgramEnabled: venueSettings.is_loyalty_program_enabled,
    }
  },

  selectParams: state => ({
    userDomain: state.appState.userDomain,
    venueSettings: state.appState.venueSettings,
  }),
}

export const selectNavigationPermissions = createShallowEqualSelector(NavigationPermissions.selectParams, NavigationPermissions.get)

svrExport('Navigation', 'permissions', NavigationPermissions)
